import { registerApplication, start } from "single-spa";

registerApplication({
  name: "@sirius/platform-core-fe",
  app: () => System.import("@sirius/platform-core-fe"),
  activeWhen: ["/"],
});

registerApplication({
  name: "@sirius/sales-fe",
  app: () => System.import("@sirius/sales-fe"),
  activeWhen: ["/#/comercial"],
});

start({
  urlRerouteOnly: true,
});
